<template>
  <div
    class="bottom-menu"
    :class="[
      {
        rounded,
        onlyIcons,
        infinity,
        noBorder
      },
      variant
    ]"
  >
    <slot />
  </div>
</template>

<script>
const variants = [
  "primary",
  "secondary",
  "danger",
  "success",
  "warning",
  "info",
  "light",
  "dark"
];

export default {
  name: "BottomMenu",

  props: {
    rounded: Boolean,
    onlyIcons: Boolean,
    infinity: Boolean,
    noBorder: Boolean,
    variant: {
      type: String,
      validator: variant => variants.includes(variant)
    }
  }
};
</script>

<style>
.bottom-menu {
  @apply max-h-14
    flex
    h-full
    bg-white
    fixed
    left-0
    right-0
    bottom-0
    border-t;
}

.bottom-menu.rounded {
  @apply rounded-t-3xl;
}
.bottom-menu.onlyIcons .bottom-menu__item-text {
  @apply hidden;
}
.bottom-menu.infinity {
  @apply overflow-x-auto;
}
.bottom-menu.infinity .bottom-menu__item {
  min-width: 20%;
}
.bottom-menu.noBorder {
  @apply border-transparent;
}

/* Variants */
.bottom-menu.primary {
  @apply bg-blue-500 border-transparent;
}
.bottom-menu.primary .bottom-menu__item {
  @apply text-white text-opacity-60;
}
.bottom-menu.primary .bottom-menu__item.active {
  @apply text-opacity-100;
}

.bottom-menu.secondary {
  @apply bg-gray-500 border-transparent;
}
.bottom-menu.secondary .bottom-menu__item {
  @apply text-white text-opacity-60;
}
.bottom-menu.secondary .bottom-menu__item.active {
  @apply text-opacity-100;
}

.bottom-menu.danger {
  @apply bg-red-500 border-transparent;
}
.bottom-menu.danger .bottom-menu__item {
  @apply text-white text-opacity-60;
}
.bottom-menu.danger .bottom-menu__item.active {
  @apply text-opacity-100;
}

.bottom-menu.success {
  @apply bg-green-500 border-transparent;
}
.bottom-menu.success .bottom-menu__item {
  @apply text-white text-opacity-60;
}
.bottom-menu.success .bottom-menu__item.active {
  @apply text-opacity-100;
}

.bottom-menu.warning {
  @apply bg-yellow-500 border-transparent;
}
.bottom-menu.warning .bottom-menu__item {
  @apply text-white text-opacity-60;
}
.bottom-menu.warning .bottom-menu__item.active {
  @apply text-opacity-100;
}

.bottom-menu.info {
  @apply bg-purple-500 border-transparent;
}
.bottom-menu.info .bottom-menu__item {
  @apply text-white text-opacity-60;
}
.bottom-menu.info .bottom-menu__item.active {
  @apply text-opacity-100;
}

.bottom-menu.light {
  @apply bg-opacity-80 bg-white border-transparent;
  backdrop-filter: blur(8px);
}

.bottom-menu.dark {
  @apply bg-gray-900 border-transparent;
}
.bottom-menu.dark .bottom-menu__item {
  @apply text-white text-opacity-60;
}
.bottom-menu.dark .bottom-menu__item.active {
  @apply text-opacity-100;
}
</style>
