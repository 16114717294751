<template>
  <div class="bottom-menu__item" :class="{ active }">
    <icon class="bottom-menu__item-icon" v-if="icon" :icon="icon">
      {{ icon }}
    </icon>

    <span class="bottom-menu__item-text" v-if="text">
      {{ text }}
    </span>
  </div>
</template>

<script>
export default {
  name: "BottomMenuItem",

  props: {
    icon: String,
    text: String,
    active: Boolean
  }
};
</script>

<style>
/**/
.bottom-menu__item {
  @apply flex flex-1 flex-col items-center justify-center h-14;
  @apply text-gray-500;
}
.bottom-menu__item-icon {
  @apply text-2xl;
  margin-top: -4px;
}
.bottom-menu__item-text {
  font-size: 9px;
  margin-top: -2px;
  @apply font-medium;
}

.bottom-menu__item.active {
  @apply text-blue-500;
}
</style>
