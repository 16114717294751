<template>
  <div class="min-h-screen min-w-screen bg-gray-50">
    <bottom-menu>
      <bottom-menu-item icon="mdi-inbox" text="Today" />
      <bottom-menu-item active icon="mdi-calendar" text="Calendar" />
      <bottom-menu-item icon="mdi-virus-outline" text="COVID-19" />
      <bottom-menu-item icon="mdi-menu" text="Настройки" />
    </bottom-menu>
  </div>
</template>

<script>
import BottomMenu from "@/app/mobile-ui/BottomMenu";
import BottomMenuItem from "@/app/mobile-ui/BottomMenuItem";

export default {
  name: "Mobile",

  components: { BottomMenuItem, BottomMenu }
};
</script>
